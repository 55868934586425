<template>
  <div class="wniosker-offers">
    <div v-if="Object.keys(getGroupsFromOffers).length !== 0" v-for="(items, key) in getGroupsFromOffers">
      <span class="d-block fw-600 mb-4">
        <v-wniosker-offer-label :offerGroupName="key"/>
        <v-wniosker-select-all-button :items="items" :group="key"/>
      </span>
      <div class="row">
        <v-collection :items="items" :group="key">
          <template v-slot:default="{item}">
            <component v-if="Object.keys(this.$options.components).includes('VWnioskerOfferTemplate_' + item.template_id)" :is="'v-wniosker-offer-template_' + item.template_id" :offer="item"/>
            <v-wniosker-offer-template_default v-else :offer="item" />
          </template>
          <template v-slot:loading>
            <div class="py-3">
              <v-loading color="secondary" text="Trwa wczytywanie..."/>
            </div>
          </template>
        </v-collection>
      </div>
    </div>
    <div v-else class="d-flex">
      <div class="col-md-3">
        <div class="product mb-3">
          <div class="p-3">
            <div class="py-1 px-5 mx-3">
              <img src="../../assets/loando-logo-dark.svg" class="logo-image"/>
            </div>
            <div class="text-primary fs-5 fw-600 py-3">
              Szukamy oferty dla <br/> Twojego klienta
            </div>
          </div>
          <div class="d-grid gap-2">
            <v-button color="gold" @click="$emit('refresh')" size="lg" label="Sprawdź dostępność" icon="refresh"/>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="product blur  mb-3">
          <div class="p-3">
            <div class="py-1 px-5 mx-3">
              <img src="../../assets/loando-logo-dark.svg" class="logo-image"/>
            </div>
            <div class="text-primary fs-5 fw-600 py-3">
              Szukamy oferty dla <br/> Twojego klienta
            </div>
          </div>
          <div class="d-grid gap-2">
            <v-button color="light" @click="$emit('refresh')" size="lg" label="Sprawdź dostępność" icon="refresh"/>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="product blur  mb-3">
          <div class="p-3">
            <div class="py-1 px-5 mx-3">
              <img src="../../assets/loando-logo-dark.svg" class="logo-image"/>
            </div>
            <div class="text-primary fs-5 fw-600 py-3">
              Szukamy oferty dla <br/> Twojego klienta
            </div>
          </div>
          <div class="d-grid gap-2">
            <v-button color="light" @click="$emit('refresh')" size="lg" label="Sprawdź dostępność" icon="refresh"/>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="product blur  mb-3">
          <div class="p-3">
            <div class="py-1 px-5 mx-3">
              <img src="../../assets/loando-logo-dark.svg" class="logo-image"/>
            </div>
            <div class="text-primary fs-5 fw-600 py-3">
              Szukamy oferty dla <br/> Twojego klienta
            </div>
          </div>
          <div class="d-grid gap-2">
            <v-button color="light" @click="$emit('refresh')" size="lg" label="Sprawdź dostępność" icon="refresh"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VWnioskerSelectAllButton from "@/shared/wniosker/select.all.button.vue";
import VWnioskerOfferLabel from "@/shared/wniosker/offers/offer.label.vue";
import VWnioskerOfferTemplate_1354 from "@/shared/wniosker/templates/template.1354.vue";
import VWnioskerOfferTemplate_1366 from "@/shared/wniosker/templates/template.1366.vue";
import VWnioskerOfferTemplate_1376 from "@/shared/wniosker/templates/template.1376.vue";
import VWnioskerOfferTemplate_1873 from "@/shared/wniosker/templates/template.1873.vue";
import VWnioskerOfferTemplate_1875 from "@/shared/wniosker/templates/template.1875.vue";
import VWnioskerOfferTemplate_2639 from "@/shared/wniosker/templates/template.2639.vue";
import VWnioskerOfferTemplate_2641 from "@/shared/wniosker/templates/template.2641.vue";
import VWnioskerOfferTemplate_2642 from "@/shared/wniosker/templates/template.2642.vue";
import VWnioskerOfferTemplate_2283 from "@/shared/wniosker/templates/template.2283.vue";
import {OffersLabelsEnum} from "@/enums/offers/offers.label.enum";
import VWnioskerOfferTemplate_default from "@/shared/wniosker/templates/template.default.vue";


export default {
  name: "v-wniosker-offers",
  components: {
    VWnioskerOfferTemplate_default,
    VWnioskerOfferTemplate_1354,
    VWnioskerOfferTemplate_1366,
    VWnioskerOfferTemplate_1376,
    VWnioskerOfferTemplate_1873,
    VWnioskerOfferTemplate_1875,
    VWnioskerOfferTemplate_2639,
    VWnioskerOfferTemplate_2641,
    VWnioskerOfferTemplate_2642,
    VWnioskerOfferTemplate_2283,
    VWnioskerOfferLabel, VWnioskerSelectAllButton
  },
  emits: ['refresh'],
  props: {
    offer: {},
    root: {type: Boolean, default: true},
  },
  data() {
    return {
      sortedOffers: [],
    }
  },
  computed: {
    OffersLabelsEnum() {
      return OffersLabelsEnum
    },
    getGroupsFromOffers() {
      let groups = {};
      this.offer?.elements?.map((item) => {
        if (!item.groupKey || !OffersLabelsEnum[item.groupKey]) {
          if (!groups['other']) {
            groups['other'] = [];
          }
          groups['other'].push(item);
        } else {
          if (!groups[item.groupKey]) {
            groups[item.groupKey] = [];
          }
          groups[item.groupKey].push(item);
        }
      });

      return groups;
    }
  }
}
</script>

<style scoped>

</style>
<template>
  {{ generateLabel }}
</template>
<script>
import { OffersLabelsEnum } from "@/enums/offers/offers.label.enum";

export default {
  name: 'VWnioskerOfferLabel',
  props: {
    offerGroupName: { type: String, default: null }
  },
  computed: {
    generateLabel: function() {
      return OffersLabelsEnum[this.offerGroupName] ?? '';
    }
  }
}
</script>
<template>
  <div class="col-md-3">
    <div class="product mb-3 " :class="{active: offer.value, 'selected': offer.checked}">
      <div class="product-description p-3 flex-grow-1">
        <div class="py-1">
          <div class="product-logo-image d-flex justify-content-center align-items-center">
            <img :alt=" offer.label" :src="offer.thumbnail" class="logo-image" style="width: 126px; height: 42px;"/>
          </div>
        </div>
        <div class="row">
          <div class="d-flex flex-row justify-content-center align-items-center gap-5">
            <div class="text-center offer-amount-tile">
              <span class="text-end main-key">Kwota</span>
              <span class="number">{{ parseFloat(offer.amount).toPrettyFormat() }}</span>
              <span class="unit">{{ offer.amountLabel }}</span>
            </div>
            <div class="text-center offer-amount-tile">
              <span class="text-end main-key">Okres</span>
              <span class="number">{{ offer.period }}</span>
              <span class="unit">{{ offer.periodLabel }}</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <v-model.agent.affiliate :lead="this.$route.params.lead" :application="offer.offer" v-slot="{fetch, loading}">
          <label class="btn" @click="fetch">
            <v-loading v-if="loading" text="Przekierowuję" />
            <v-icon icon="link" v-if="!loading" />
            <span v-if="!loading">Wypełnij wniosek</span>
          </label>
        </v-model.agent.affiliate>
      </div>
    </div>
  </div>
</template>
<script>
import VControlHidden from "@/components/Control/Hidden.vue";
import offerMixin from "@/mixins/offer";
import VLoading from "@/components/Loading.vue";

export default {
  name: "v-wniosker-offer-template_2283",
  mixins: [offerMixin],
  data() {
    return {
      offerMixin: offerMixin
    }
  },
  components: {VLoading, VControlHidden},
  mounted() {
    this.offer.checked = false;
  },
  props: {
    offer: {required: true}
  },
}
</script>
<style scoped>
.btn::before {
  display: none !important;
}
.main-key {
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: #293042;
}
.selected {
  border-color: #293042;
  background: #FFFFFF;
  box-shadow: 0 0 0 5px rgba(40, 79, 134, 0.2);
}
</style>
import model from '../mixins/model';

export default {
    mixins: [model],
    props: {
        lead: null,
        application: null
    },
    model() {
      return {};
    },
    methods: {
        fetch() {
            this.dispatch((resolve,reject) => {
                this.$http.request({ url: 'out/accept/:lead/:application', routeParams: {lead: this.lead, application: this.application}, method: 'GET'})
                    .then((response) => {
                        window.location.href = response.data.redirect;
                    })
                    .catch(reject)
            });
        },
    }
}